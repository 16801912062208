.active {
  background-color: var(--ono-primary);
  color: var(--ono-white);
  font-size: 13px;
}
.active_link {
  color: var(--ono-primary);
  border-bottom: 3px solid var(--ono-primary);
}
.tab {
  border: 1px solid var(--ono-border-color);
  background-color: var(--ono-select-background);
  font-size: 13px;
}
