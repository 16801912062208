ul li {
  list-style: none;
  // background-color: #fff;
}

.border_style::before {
  content: "";
  position: absolute;
  top: 42px;
  height: 1px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
li {
  z-index: 99;
}

.orderStatus {
  color: rgb(var(--rgb-primary));
  padding: 0.65rem 1.1rem;
  border-radius: 0.375rem;
  background-color: var(--ono-select-background);
  // &::before {
  //   content: " ";
  //   height: 0.4rem;
  //   width: 0.4rem;
  //   display: inline-block;
  //   background-color: var(--ono-primary);
  //   border-radius: 50%;
  //   margin-right: 0.4rem;
  // }
  &[data-status="DISBURSED"] {
    border-color: rgb(var(--rgb-primary));
    // color: rgb(var(--rgb-primary));
  }
  &[data-status="INITIATED"] {
    border-color: #ff9900;
    color: #ff9900;
    background-color: #ffe5da;
  }
  &[data-status="CLOSED"] {
    border-color: rgb(var(--rgb-primary));
    color: rgb(var(--rgb-primary));
  }
  &[data-status="PENDING"] {
    border-color: #ff9900;
    color: #ff9900;
    background-color: #ffe5da;
  }
  &[data-status="APPROVED"] {
    border-color: #ff9900;
    color: #ff9900;
    background-color: #ffe5da;
  }
  &[data-status="REJECTED"] {
    border-color: var(--ono-red);
    color: var(--ono-red);
    background-color: #ffe5da;
  }
  &[data-status="REVERTED"] {
    border-color: var(--ono-blue);
    color: var(--ono-blue);
    background-color: #d1e3ff;
  }
  &[data-status="FAILED"] {
    border-color: var(--ono-red);
    color: var(--ono-red);
    background-color: #ffe5da;
  }
  &[data-status="CANCELLED"] {
    border-color: var(--ono-red);
    color: var(--ono-red);
    &::before {
      background-color: var(--ono-red);
    }
  }
}
