@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: white;
  /* background-color: var(--ono-primary-background); */
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
/* background color classs */
.bg-white {
  background-color: #ffffff;
}

.bg-yellow {
  background-color: var(--ono-yellow);
}
.bg-blue {
  background-color: var(--ono-blue);
}
.bg-primary {
  background-color: var(--ono-primary) !important;
}
.bg-light-primary {
  background: var(--ono-select-background);
}
.bg-primary-tint {
  background-color: #E7F8EB ;
}
.bg-red-tint {
  background-color: #FFEFEF;
}
.bg-gray {
  background-color: #f9f9f9;
}
.bg-gray-bg {
  background-color: #eff3ef;
}
.bg-select {
  background-color: var(--ono-select-background);
}
.bg-light-red {
  background-color: #f3d7d7;
}
.bg-gray-dark {
  background-color: #f4f4f4;
}
.bg-red {
  background-color: #d43939;
}
/* //text color classes/ */
.color-primary {
  color: var(--ono-primary);
}
.color-blue {
  color: var(--ono-blue);
}

.color-red {
  color: var(--ono-red);
}
.color-yellow {
  color: var(--ono-yellow);
}
.color-orange {
  color: var(--ono-orange);
}
.color_red {
  color: var(--ono-red);
}
.color-text-primary {
  color: var(--ono-primary-text);
}
.color-sub-text {
  color: var(--ono-subtext);
}
.color-brown {
  color: #6a422e;
}
.bg-lightRed {
  background-color: #ffe5da;
}
.br-5 {
  border-radius: 5px;
}
.br-50 {
  border-radius: 50%;
}
.br-100 {
  border-radius: 100%;
}
.br-10 {
  border-radius: 8px;
}
.border-blue {
  border-color: var(--ono-blue);
}
.border-primary {
  border: 1px solid var(--ono-primary);
}
.border-gray {
  border: 1px solid var(--ono-gray);
}
.border-red {
  border: 1px solid var(--ono-red);
}
/*hover class*/
.hv-primary {
  &:hover {
    background-color: var(--ono-select-background);
  }
}

.bg-yellow {
  background-color: #fff536;
}
:root {
  --rgb-primary: 22, 161, 44;
  --rgb-border-primary: 228, 228, 228;
  --ono-primary: rgb(var(--rgb-primary), 1);
  --ono-yellow: #fff536;
  --ono-orange: #ff9901;
  --ono-red: #d43939;
  --ono-blue: #0066ff;
  --ono-gray: #d3d3d3;
  --ono-primary-background: #eff3ef;
  --ono-select-background: #d7f3dd;
  --ono-primary-text: #121212;
  --ono-white: #ffffff;
  --ono-subtext: #4d4d4d;
  --ono-border-primary: rgb(var(--rgb-border-primary), 1);
  --main-heading: 24px;
  --header2-text: 20px;
  --header3-text: 16px;
  --btn-text: 15px;
  --header5-text: 14px;
  --body-text: 13px;
  --body-small-text: 12px;
}
.text_overflow {
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}