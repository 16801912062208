.selectedIcon {
  display: none;
  position: absolute;
  color: var(--ono-primary);
  top: 0rem;
  right: 1rem;
  background-color: white;
}
.selected {
  img {
    border: 2px solid rgb(var(--rgb-primary), 0.6);
    border-radius: 50%;
  }
  .selectedIcon {
    display: block;
  }
}
