.number {
    height: 36px;
    width: 36px;
    cursor: pointer;
    &.active {
      background-color: var(--ono-primary);
      border-radius: 50%;
      color: white;
    }
  }
  .hand {
    width: 2px;
    background-color: var(--ono-primary);
  }
  
  .timeBox {
    background-color: #d4dfd48c;
    border-radius: 5px;
    &.active {
      background-color: #91e5918c;
    }
  }
  