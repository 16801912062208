.cropQuantity {
  top: 0px;
  right: -0.1rem;
  color: white;
}
.crops_row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.cropHeight {
  height: 70px;
}
