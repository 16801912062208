.billContainer {
    border-width: 2px;
    border-style: solid;
    // border-color: rgb(var(--rgb-primary), 1);
    border-radius: 10px;
  }
  .billheader {
    // background-color: rgb(var(--rgb-primary), 0.85);
    color: white;
    .caInfo {
      border-bottom: 1px dashed white;
    }
    .shopInfo {
      // background: #e8e809b0;
      border-radius: 1.1rem;
    }
  }
  .table {
    .heading {
      border-top: 1px solid var(--ono-border-color);
      font-size: 13px;
      font-weight: 600;
      // background-color: var(--ono-select-background);
      // border-top-left-radius: 0.8rem;
      // border-top-right-radius: 0.8rem;
      > * {
        &:last-child {
          border: none;
        }
      }
    }
    > * {
      border-bottom: 1px solid var(--ono-border-color);
      font-size: 12px;
      > * {
        border-right: 1px solid var(--ono-border-color);
        > * {
          padding: 0.65rem;
          border-right: 1px solid var(--ono-border-color);
        }
      }
    }
  }
  
  .stamp_img {
    position: absolute;
    top: -1.4rem;
    right: 0rem;
    width: 8rem;
  }
  