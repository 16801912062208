.stepProgressBar {
  width: 80px;
  height: 1.5px;
  background-color: var(--ono-border-color);
  transition: all 0.2s;

  &[data-active="true"] {
    background-color: var(--ono-primary);
  }
}

.stepCnt {
  padding: 5px 1rem;
  border-radius: 3px;
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: capitalize;
  background-color: var(--ono-border-color);
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.5s;

  &[data-selected="true"] {
    background-color: var(--ono-primary);
    color: var(--ono-white);

    &:hover {
      background: #2cac42;
    }
  }

  &:hover {
    box-shadow: var(--primary-box-shadow);
  }
}
