.billItem {
  //   border: 1px solid;
  &:hover {
    background-color: var(--ono-select-background);
  }
  &[data-selected="true"] {
    background-color: var(--ono-select-background);
    border: 1px solid var(--ono-primary) !important;
  }
}
