.partner {
  cursor: pointer;
  &:hover {
    background: var(--ono-select-background);
  }
  &[data-selected="true"] {
    border: 1px solid var(--ono-primary) !important;
    background: var(--ono-select-background);
    color: #171717;
  }
}
