.table {
  .heading {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid var(--ono-border-color);
    border-top-right-radius: 0.6rem;
    border-top-left-radius: 0.6rem;
  }
  margin-bottom: 20px;
  .tbody {
    > div {
      font-size: 12px;
      border-bottom: 1px solid #e5e7eb;
      border-left: 1px solid #e5e7eb;
      &:hover {
        // background-color: var(--ono-select-background);
      }

      &[data-selected="true"] {
        background-color: var(--ono-primary);
        // border-top-left-radius: 0.3rem;
        // border-top-right-radius: 0.3rem;
        color: white;
        .amount {
          color: white;
        }
      }
      &[data-selected="false"] {
        &[data-type="seller"] {
          .amount {
            color: var(--ono-red);
          }
        }
        &[data-type="buyer"] {
          .amount {
            color: var(--ono-primary);
          }
        }
      }

      > * {
        padding: 0.65rem;
      }
    }
    .borderedCol {
      > * {
        // height: 100%;
        border-right: 1px solid #e5e7eb;
      }
    }
  }
}
