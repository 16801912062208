.selectbox {
  padding: 5px 5px;
  color: var(--ono-primary);
}
.selectUl {
  position: fixed;
  z-index: 120;
  border: 1px solid #d0cece;
  border-radius: 0.5rem;
  background: white;
  overflow: hidden;
  cursor: pointer;
  > li {
    padding: 0.5rem 0.6rem;
    &[data-selected="true"] {
      background: var(--ono-primary);
      color: white;
      cursor: not-allowed;
    }
    &[data-selected="false"] {
      &:hover {
        background: #dcede1;
      }
    }
    &[data-disabled="true"] {
      background: #d0cece;
    }
    &[data-disabled="true"] {
      &:hover {
        background: #d0cece;
        cursor: auto;
      }
    }
  }
}
