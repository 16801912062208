.container {
  &[data-view="vertical"] {
    flex-direction: column;
  }
  &[data-view="horizontal"] {
    flex-direction: row;
    // justify-content: space-between;
    gap: 15px;
  }
  .radio {
    .radiobox {
      border: 1px solid #e4e4e4;
      border-radius: 10px;
      padding: 5px;
    }
    svg {
      font-size: 1.2rem;
    }
    .active {
      color: var(--ono-primary);
    }
    .activeBox {
      background-color: var(--ono-select-background);
      border: 1px solid var(--ono-primary);
      color: var(--ono-primary);
      border-radius: 10px;
      padding: 5px;
    }
    .lable {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--ono-subtext);
      margin: 0px;
    }
  }
}
